<template>
    <div class="security">
        <div class="security-container">
            <div class="security-title">安全设置</div>
            <div class="security-center">
                <div class="security-list" v-if="status !== 0">
                    <div><h2>企业账号</h2><span>{{account}}</span></div>
                </div>
                <div class="security-list" v-if="security_phone">
                    <div><h2>手机号</h2><span>{{security_phone}}</span></div>
                    <p @click="onModify">修改</p>
                </div>
                <div class="security-list" v-else>
                    <div><h2>手机号</h2><span>{{phone}}</span></div>
                    <p @click="onModify">修改</p>
                </div>
                <div class="security-list">
                    <div><h2>密码</h2><span>********</span></div>
                    <p @click="onModifyPass">修改</p>
                </div>
                <div class="security-list">
                    <div><h2>微信授权</h2></div>
                    <p @click="onAuthorization">查看</p>
                </div>
            </div>   
            <div class="security-button"><el-button @click="onLagout">退出登录</el-button></div>
        </div>
    </div>
</template>

<script>
import { logout } from "../../api/login"
export default {
    components:{

    },
    data(){
        return{
            phone:"",
            account:"",
            security_phone:"",
            status:""
        }
    },
    created(){
        this.phone = JSON.parse(localStorage.getItem('phone'))
        this.account = JSON.parse(localStorage.getItem('account'))
        this.security_phone = JSON.parse(localStorage.getItem('security_phone'))
        this.status = localStorage.getItem('status')
        console.log(this.status)
    },
    methods:{
        onModify(){
            this.$router.push("/modify")
        },

        onModifyPass(){
            this.$router.push("/modifyPass")
        },

        onAuthorization(){
            this.$router.push("/authorization")
        },

        onLagout(){
            logout().then((res) => {
                if(res.data.code == 20000){
                    this.$router.push("/login")
                    this.$store.commit('del_token')
                    window.localStorage.clear(); 
                    this.$message.success(res.data.message)
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.security{
    width:100%;
    position:relative;
    .security-container{
        width:100%;
        height:592px;
        background-color:#FFFFFF;
        padding:30px;
        .security-title{
            color:#000000;
            font-size:20px;
            font-weight:500;
        }
        .security-center{
            width:100%;
            margin-top:8px;
            .security-list{
                width:100%;
                height:80px;
                border-bottom:1px solid #E9E9E9;
                display:flex;
                align-items:center;
                justify-content: space-between;
                div{
                    display:flex;
                    flex-direction: column;
                    h2{
                        color:#999999;
                        font-size:14px;
                        padding-bottom:10px;
                    }
                    span{
                        color:#333333;
                        font-size:14px;
                    }
                }
                p{
                    color:#123E90;
                    font-size:14px;
                    cursor: pointer;
                }
            }
        }
    }
    .security-button{
        display: flex;
        align-items:center;
        justify-content: center;
        margin-top:20px;
    }
    /deep/ .el-button{
        background-color:#123E90;
        border:none;
        color:#ffffff;
        margin-top:20px;
    }
}

</style>